import React, { Component } from 'react'
import YourName from '../../components/YourName/';

export class Your_Name extends Component {
  render() {
    return (
      <div>
        <YourName/>
      </div>
    )
  }
}

export default Your_Name;