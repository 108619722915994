import React, { Component } from 'react'
import Contact from '../../components/Contact/';

export class Contact_Me extends Component {
  render() {
    return (
      <div>
        <Contact/>
      </div>
    )
  }
}

export default Contact_Me;